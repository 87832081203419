import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import DoctorCard from './components/DoctorCard';
import { ReactComponent as ProIcon } from './prodoctorov.svg';
import { ReactComponent as YandexIcon } from './yandex.svg';
import { ReactComponent as TwoGisIcon } from './two-gis.svg';
import { ReactComponent as NapopravkuIcon } from './napopravku.svg';
import { ReactComponent as DocdocIcon } from './docdoc.svg';
import { ReactComponent as GoogleMapsIcon } from './google-maps.svg';
import { ReactComponent as PersonSuccessSvg } from './person-success.svg';

import { createFeedback } from '../../api';
import { FEEDBACK_SITE_NAMES, Service } from '../../constants/feedback';
import { AppRoute } from '../../constants/routes';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../helpers/localStorageHelper';

function FeedbackSuccessPage() {
  const { slug }: { slug?: string } = useParams();
  const [info] = React.useState(() => getLocalStorageItem('info'));
  const [isDoctor, setIsDoctor] = React.useState(false);

  const isDoctorFeedback = !!info?.doctor;

  if (!slug || !info) {
    removeLocalStorageItem('info');

    return <Redirect to={AppRoute.HOME} />;
  }

  const isProDoctorClinicFeedback = info?.clinic.feedbackSites?.includes(
    FEEDBACK_SITE_NAMES.PRODOCTOROV
  );

  const isOtherFeedbacks = [
    FEEDBACK_SITE_NAMES.NAPOPRAVKU,
    FEEDBACK_SITE_NAMES.TWOGIS,
    FEEDBACK_SITE_NAMES.YANDEXMAP,
    FEEDBACK_SITE_NAMES.GOOGLEMAPS,
  ].some((fb) => info?.clinic.feedbackSites?.includes(fb));

  const isDoctorCard = isDoctor || (isDoctorFeedback && !isOtherFeedbacks);

  const onSubmitClick = (service: Service) => async () => {
    if (service === Service.PRO && !isDoctorCard && isDoctorFeedback && info?.clinic?.isProdoctorovDoctorFeedback) {
      setIsDoctor(true);
      return;
    }

    const feedback = await createFeedback(slug, 5, service);

    if (feedback) {
      const {
        clinic: {
          prodoctorovId,
          twoGisId,
          yandexMapId,
          twoGisCity = 'moscow',
          slug,
          proDoctorovUrl,
          naPopravkuUrl,
          yandexUrl,
          googleMapUrl,
          twoGisUrl,
          docdocUrl,
        },
      } = feedback;
      let url;

      switch (service) {
        case Service.PRO:
          url = proDoctorovUrl
            ? proDoctorovUrl
            : `https://prodoctorov.ru/new/rate/lpu/${prodoctorovId}/`;
          break;
        case Service.GIS:
          url = twoGisUrl
            ? twoGisUrl
            : `https://2gis.ru/${twoGisCity}/firm/${twoGisId}/tab/reviews?writeReview`;
          break;
        case Service.YANDEX:
          url = yandexUrl
            ? yandexUrl
            : `https://yandex.ru/maps/org/${slug}/${yandexMapId}/reviews/?add-review=true`;
          break;
        case Service.GOOGLE:
          url = googleMapUrl;
          break;
        case Service.NAPOPRAVKU:
          url = naPopravkuUrl;
          break;
        case Service.DOCDOC:
          url = docdocUrl;
          break;
      }

      window.location.replace(url);
    }
  };

  return (
    <Box className='feedback-page'>
      <Card className='card'>
        <Box className='card__image-wrap'>
          <PersonSuccessSvg className='card__image'/>
        </Box>
        <CardContent sx={{ padding: '24px 0' }}>
          {/*<Typography*/}
          {/*  sx={{*/}
          {/*    fontFamily: '"Nunito Sans", sans-serif',*/}
          {/*    mb: 2,*/}
          {/*  }}*/}
          {/*  fontSize={24}*/}
          {/*  lineHeight={'32px'}*/}
          {/*  fontWeight={600}*/}
          {/*>*/}
          {/*  Мы очень рады, что обслуживание в нашей клинике Вам понравилось!*/}
          {/*</Typography>*/}

          <Typography
            sx={{ fontFamily: '"Nunito Sans", sans-serif', mb: 1 }}
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={600}
          >
            {info?.successFeedbackMessage ? info.successFeedbackMessage : `Будем признательны, если Вы уделите минутку и оставите ${
              isDoctorCard ? 'отзыв о нашем докторе' : 'о нас отзыв'
            }.`}
          </Typography>

          {info.successMessage ? (
            <Typography
              sx={{ fontFamily: '"Nunito Sans", sans-serif', mb: 2 }}
              fontSize={16}
              lineHeight={'24px'}
              fontWeight={600}
            >
              {info.successMessage}
            </Typography>
            ) : (
            <Typography
              sx={{ fontFamily: '"Nunito Sans", sans-serif', mb: 2 }}
              fontSize={16}
              lineHeight={'24px'}
              fontWeight={600}
            >
              {`После публикации отзыва мы перечислим на баланс Вашего телефона ${info?.clinic.smsPrice}
              рублей.`}
            </Typography>
          )}

          <Typography
            sx={{ fontFamily: '"Nunito Sans", sans-serif' }}
            fontSize={12}
            lineHeight='16px'
            fontWeight={800}
          >
            Модерация отзыва может занимать до 5 рабочих дней.
          </Typography>

          {isDoctorCard && info?.doctor ? (
            <>
              {isProDoctorClinicFeedback && (
                <Box
                  sx={{
                    display: 'grid',
                    alignSelf: 'stretch',
                    paddingBottom: '16px',
                  }}
                >
                  <Button
                    className='button success'
                    startIcon={<ProIcon />}
                    variant='outlined'
                    size='large'
                    onClick={onSubmitClick(Service.PRO)}
                  />
                </Box>
              )}

              {info?.clinic?.isProdoctorovDoctorFeedback && <DoctorCard doctor={info.doctor} visitSlug={slug} />}
            </>
          ) : null}
        </CardContent>

        <CardActions
          disableSpacing={true}
          sx={{
            display: 'grid',
            alignSelf: 'stretch',
            gap: '16px',
            padding: 0,
          }}
        >
          {isDoctorCard ? null : (
            <>
              {info?.clinic.feedbackSites?.includes(
                FEEDBACK_SITE_NAMES.YANDEXMAP
              ) && (
                <Button
                  className='button success'
                  startIcon={<YandexIcon height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.YANDEX)}
                />
              )}

              {info?.clinic.feedbackSites?.includes(
                FEEDBACK_SITE_NAMES.TWOGIS
              ) && (
                <Button
                  className='button success'
                  startIcon={<TwoGisIcon height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.GIS)}
                />
              )}

              {(isProDoctorClinicFeedback || isDoctorFeedback) && (
                <Button
                  className='button success'
                  startIcon={<ProIcon height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.PRO)}
                />
              )}

              {info?.clinic.feedbackSites?.includes(
                FEEDBACK_SITE_NAMES.GOOGLEMAPS
              ) && (
                <Button
                  className='button success'
                  startIcon={<GoogleMapsIcon height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.GOOGLE)}
                >
                  Google Maps
                </Button>
              )}

              {info?.clinic.feedbackSites?.includes(
                FEEDBACK_SITE_NAMES.NAPOPRAVKU
              ) && (
                <Button
                  className='button success'
                  startIcon={<NapopravkuIcon height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.NAPOPRAVKU)}
                />
              )}

              {info?.clinic.feedbackSites?.includes(
                FEEDBACK_SITE_NAMES.DOCDOC
              ) && (
                <Button
                  className='button success'
                  startIcon={<DocdocIcon width={140} height='22px' />}
                  variant='outlined'
                  size='large'
                  onClick={onSubmitClick(Service.DOCDOC)}
                />
              )}
            </>
          )}
        </CardActions>
      </Card>
    </Box>
  );
}

export default FeedbackSuccessPage;
